import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class FilterSyncUrlHelper {

    constructor(private activateRoute: ActivatedRoute) { }
    /**
     * Take a query string and update the URL query parameters with the new filter values.
     * Removes all non-filter parameters from the query string.
     * Sanitizes the newParams by replacing %20 with underscores.
     * @param query The query string to update the URL with.
     */
    getAppliedFilterQueries(query: any) {
        const queryArray = query?.split('&')
        const filterQueries = this.removeUnwantedParams(queryArray);
        this.updateQueryParams(filterQueries?.join('&'));
    }

    /**
     * Update the URL query parameters with the new filter values.
     * Sanitizes the newParams by replacing %20 with underscores.
     * @param newParams The new filter values as a string or object.
     */
    updateQueryParams(newParams: any) {
        // Sanitize the newParams: replace %20 with underscores
        const sanitizedParams = typeof newParams === 'string'
            ? newParams.replace(/%20/g, "_") // Replace %20 with _
            : JSON.stringify(newParams).replace(/%20/g, "_"); // Handle JSON strings similarly

        // Manually construct the query string
        const queryParams = sanitizedParams === 'all' ? '' : `filter/${sanitizedParams}`;

        // Update the URL manually without encoding special characters
        const newUrl = queryParams ? `${window.location.origin}${window.location.pathname}?${queryParams} ` : window.location.pathname;

        window.history.pushState({}, '', newUrl);
    }





    /**
     * Maps the query parameters from the current route snapshot to a structured array of filter objects.
     * It handles various types of query parameters such as categories, sale status, loan status, appraisal range,
     * location, collections, and sorting options. The function sanitizes values by replacing underscores with spaces
     * and splits multiple entries using commas. It also tracks the last applied sort for proper ordering.
     *
     * @returns An array of objects each representing a filter or sort option, with fields for type, value, and optional
     *          name and sortType.
     */
    mapQueryParams() {
        const queryParams = this.activateRoute.snapshot.queryParams;

        // Create URLSearchParams object
        const params = new URLSearchParams(queryParams);
        let mappedParams: Array<{ type: string, value: any, name?: string, sortType?: string }> = [];

        let lastSort = null; // Track the last applied sort
        params.forEach((value, key) => {
            const santitizedValue = value.replace(/_/g, " ");
            if (key === 'category') {
                const categories = santitizedValue.split(','); // Split categories by comma
                categories.forEach((cat) => {
                    mappedParams.push({ type: 'category', value: cat });
                });
            } else if (key === 'for_sale') {
                mappedParams.push({ type: 'sale', value, name: santitizedValue === 'yes' ? 'For sale.' : 'Not for sale.' });
            } else if (key === 'for_loan') {
                mappedParams.push({ type: 'collateral', value, name: santitizedValue === 'yes' ? 'In collateral.' : 'Not in collateral.' });
            } else if (key === 'appraisal_from') {
                // Handle appraisal range using min and max values
                const minValue = queryParams['appraisal_from'] || 0;
                const maxValue = queryParams['appraisal_to'] || 0;
                if (maxValue <= minValue) return;
                mappedParams.push({
                    type: 'appraisal',
                    value: { minValue: parseInt(minValue), maxValue: parseInt(maxValue) },
                    name: `${minValue} - ${maxValue}`
                });
            } else if (key === 'location') {
                const locations = santitizedValue.split(','); // Split location by comma
                locations.forEach((loc) => {
                    mappedParams.push({ type: 'location', value: loc });
                });
            } else if (key === 'filter/collections') {
                const collections = santitizedValue.split(','); // Split collections by comma
                collections.forEach((collection) => {
                    mappedParams.push({ type: 'collection', value: collection });
                });
            }

            // Handle any sort ending with '_sort'
            else if (key.endsWith('_sort')) {
                let sortType = key.replace('_sort', ''); // Extract the sort type from the key (e.g., 'price', 'name')
                let sortName = '';

                // Determine the sort name based on the value and sort type
                if (sortType === 'price') {
                    sortName = value === '1' ? 'By Price. (0 → 9)' : 'By Price. (9 → 0)';
                } else if (sortType === 'name') {
                    sortName = value === '1' ? 'By Name. (A → Z)' : 'By Name. (Z → A)';
                } else if (sortType === 'bid') {
                    sortName = value === '-1' ? 'By Bid' : '';
                } else if (sortType === 'purchase') {
                    sortName = value === '-1' ? 'By Purchase' : '';
                }

                // Track the last applied sort
                lastSort = { type: 'sort', value: parseInt(value), name: sortName, sortType };
            }
        });

        // Remove any existing sorts from mappedParams
        mappedParams = mappedParams.filter(param => param.type !== 'sort');

        // Add the last sort to mappedParams
        if (lastSort) {
            mappedParams.push(lastSort);
        }

        return [...mappedParams];
    }


    /**
     * Filters out any key-value pairs in the queryArray that contain unwanted keys.
     * These unwanted keys are specified in the unwantedKeys array.
     * The function returns the filtered array of query string parameters.
     * @param queryArray The query string parameters to filter.
     * @returns The filtered array of query string parameters.
     */
    removeUnwantedParams(queryArray: any) {
        const unwantedKeys = ['page', 'limit'];
        // Filter out any key-value pairs that contain unwanted keys
        const filteredArray = queryArray.filter((key: any) => {
            return !unwantedKeys.some(unwantedKey => key.includes(unwantedKey));
        });

        return filteredArray;
    }


}
